* {
  /* Defaults */
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto";
 
  /* Boxes & Cards */
  --box-border-radius: 10px;
  --box-bg: white;
  --box-text: black;

  /* Background */
  --bg-fill: darkgrey;
  --bg-login: #a22d25; 

  /* Headings */
  --col1: #f2b307;
  --col2: #a22d25;
  --col3: #11948f;
  --col4: #2d5e74;
  --col5: #be4e32;

  --headings-text: white;
  --menu-link-active: gray;
  --menu-link-inactive: #f2b307;
}

